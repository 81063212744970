<template>
  <cit-settings-manager action-url="settings/page-it/clients-section">

    <template v-slot:fields="{form, errors}">
      <div class="mb-4">
        <v-text-field v-model="form.title" dense hide-details label="Titlu" outlined/>
        <error-messages :errors="errors.title"/>
      </div>
    </template>
  </cit-settings-manager>

</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import CitSettingsManager from '@/components/layout/CitSettings/CitSettingsManager'

export default {
  components: {
    CitSettingsManager,
    ErrorMessages
  }
}
</script>
