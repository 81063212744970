<template>
  <v-tabs class="homepage-settings" left vertical>

    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">SEO</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Header</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune text</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Branduri</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Categorii</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Bg. Albastru</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Avantaje</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Text/Imagine Bg. Albastru</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Echipa de Vanzari</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Companii</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Presa</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Premii si Certificari</span>
    </v-tab>
    <v-tab class="justify-start pl-0">
      <vs-icon icon="fa-cog" icon-pack="fal" size="small"></vs-icon>
      <span class="ml-2 font-weight-bold">Sectiune Despre CIT</span>
    </v-tab>

    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <seo-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <header-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <text-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <div class="p-8">
          Se editeaza de la sectiunea de <a href="#">brand-uri</a>.
        </div>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <div class="p-8">
          Se editeaza de la listarea de <a href="https://admin.citgrup.ro/#/product-management/categories">categorii</a>
        </div>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <blue-bg-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <benefits-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <text-image-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <team-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <clients-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <press-section />
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <div class="p-8">
          Se editeaza de la sectiunea de <a href="#">premii si certificari</a>.
        </div>
      </v-card>
    </v-tab-item>
    <v-tab-item class="ml-5">
      <div v-if="franchisee && franchisee.domain" class="mt-3 mb-4">
        <a @click=" NavigationLink(`https://` + franchisee.domain + `/it`)" target="_blank">
          Click aici pentru a previzualiza pagina pe care o editezi
        </a>
      </div>
      <v-card elevation="3" rounded>
        <about-section />
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import SeoSection from './components/SeoSection'
import HeaderSection from './components/HeaderSection'
import TextSection from './components/TextSection'
import BenefitsSection from './components/BenefitsSection'
import TeamSection from './components/TeamSection'
import AboutSection from './components/AboutSection'
import PressSection from './components/PressSection'
import BlueBgSection from "./components/BlueBgSection"
import TextImageSection from "./components/TextImageSection";
import ClientsSection from "./components/ClientsSection";

export default {
  name: 'index',
  components: {
    ClientsSection,
    PressSection,
    AboutSection,
    TeamSection,
    BenefitsSection,
    BlueBgSection,
    HeaderSection,
    TextSection,
    SeoSection,
    TextImageSection
  },
  computed: {
    franchisee_id() {
      return this.$store.getters['auth/currentUser'].franchisee_id
    },
    franchisee() {
      return this.$store.getters['singleFranchisee/item']
    }
  },
  methods: {
    loadFranchisee() {
      this.franchisee_id && this.$store.dispatch('singleFranchisee/load', this.franchisee_id)
    },
    NavigationLink(url) {
      window.open(url, '_blank', 'noreferrer');
    },
  },
  created() {
    this.loadFranchisee()
  }
}
</script>

<!--suppress CssUnusedSymbol -->
<style>
.homepage-settings .v-tabs-slider-wrapper {
  right: 0;
  left: inherit !important;
}
</style>
