<template>
  <cit-settings-manager action-url="settings/page-it/about-section">

    <template v-slot:fields="{form, errors}">
      <div class="mb-4">
        <v-text-field v-model="form.title" dense hide-details label="Titlu" outlined/>
        <error-messages :errors="errors.title"/>
      </div>
      <div class="mb-4">
        <label>Continut</label>
        <wysiwyg v-model="form.description"/>
        <error-messages :errors="errors.description"/>
      </div>
      <div class="mb-4">
        <v-row>
          <v-col>
            <v-text-field v-model="form.button_text" dense hide-details label="Text Buton" outlined/>
            <error-messages :errors="errors.button_text"/>
          </v-col>
          <v-col>
            <v-text-field v-model="form.button_link" dense hide-details label="Link Buton" outlined/>
            <error-messages :errors="errors.button_link"/>
          </v-col>
        </v-row>
      </div>
    </template>
  </cit-settings-manager>

</template>

<script>
import ErrorMessages from '@/components/general-form/ErrorMessages'
import CitSettingsManager from '@/components/layout/CitSettings/CitSettingsManager'
import Wysiwyg from "../../../../components/general-form/Wysiwyg";

export default {
  components: {
    Wysiwyg,
    CitSettingsManager,
    ErrorMessages
  }
}
</script>
